<template>
  <div
    class="row justify-center bg-grey-3 items-start"
    style="min-height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:300px">
      <q-card-section>
        <a class="text-grey text-h6">IDENTITAS</a>
      </q-card-section>
      <q-form @submit="confirm = true">
        <q-card-section class="bg-indigo-1">
          <q-input
            filled
            @change="isChange = true"
            label="Nama"
            outlined
            v-model="guruDetail.nama"
            lazy-rules
            :rules="[
              (val) => (val && val.length > 0) || 'Nama Tidak Boleh Kosong',
            ]"
          ></q-input>

          <q-input
            filled
            readonly
            label="NIK"
            @change="isChange = true"
            outlined
            v-model="guruDetail.nik"
            class="col-6"
            lazy-rules
            :rules="[
              (val) => (val && val.length > 0) || 'Nama Tidak Boleh Kosong',
            ]"
          ></q-input>

          <div class="column q-gutter-md q-pb-lg">
            <q-input
              filled
              label="NIGN"
              @change="isChange = true"
              outlined
              v-model="guruDetail.nign"
              class="col-6"
            ></q-input>

            <q-input
              filled
              label="No.HP"
              @change="isChange = true"
              outlined
              v-model="guruDetail.hp"
              class="col-6"
            ></q-input>

            <q-input
              filled
              label="E-Mail"
              @change="isChange = true"
              outlined
              v-model="guruDetail.email"
              class="col-6"
            ></q-input>
          </div>
        </q-card-section>
        <q-card-actions>
          <q-btn
            flat
            class="full-width q-mb-sm"
            color="primary"
            label="back"
            @click="$router.go(-1)"
          ></q-btn>
          <q-btn
            outline
            class="full-width q-mb-sm"
            color="primary"
            label="Ubah Password"
            to="/pengaturaneditpassword"
          ></q-btn>
          <q-btn
            :disable="!isChange"
            class="full-width"
            type="submit"
            color="primary"
            label="simpan"
          ></q-btn>
          <div class="column q-gutter-md"></div>
        </q-card-actions>
      </q-form>
    </q-card>
    <q-dialog v-model="confirm">
      <q-card>
        <q-card-section>Apa anda yakin ingin menyimpan data?</q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="close" color="primary" v-close-popup></q-btn>
          <q-btn
            unelevated
            label="Ya"
            color="primary"
            @click="simpan"
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      confirm: false,

      isChange: false,
      guruDetail: {},
      selJenjang: "",
    };
  },
  async mounted() {
    await this.getCurUser();
    await this.getdata();
  },
  methods: {
    ...mapGetters(["getCurrentUser"]),
    async getCurUser() {
      this.user = await this.getCurrentUser();
    },
    async simpan() {
      await this.$http.put(`/pengaturan/user/update`, this.guruDetail);
      this.$q.notify({
        message: "Profil Berhasil Di Update",
        color: "posititve",
      });
      await this.getdata();
    },
    async getdata() {
      let resp = await this.$http.get(
        `/pengaturan/user/getdata/${this.user.id}`
      );
      this.guruDetail = resp.data;
    },
  },
};
</script>

<style lang="scss" scoped></style>
